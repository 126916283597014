<template>
  <div>loading...</div>
</template>

<script>
import AuthService from '@/services/AuthService.js';

import { mapActions } from 'vuex';

export default {
  async beforeMount() {
    this.setAuthorization({
      progress: 50,
      info: this.$t('sessionAuthorize')
    });
    var authService = new AuthService();
    var user = await authService.signinRedirectCallback();
    if (
      user?.profile?.role &&
      (/admin/.test(user.profile.role) ||
        /user/.test(user.profile.role) ||
        /dpt/.test(user.profile.role))
    ) {
      this.initializeSession();

      // TODO: find a way so that we don't have to replace every alias
      // slug. Ideally we get the return url or the slug from the database.
      // const returnUrl = user.returnUrl.replace('/hays-is/', '/hays/');
      this.$router.push(user.returnUrl);
    } else {
      window.location.href = '/#/error-401';
    }
  },
  methods: {
    ...mapActions({
      setAuthorization: 'setAuthorization',
      initializeSession: 'initializeSession'
    })
  }
};
</script>
